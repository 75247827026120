import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../../environments/environment"

@Injectable()
export abstract class FleetCommandService {
  static APITYPE_COMMAND = 'command';
  static APITYPE_QUERY = 'query';

  protected abstract api : string;
  protected abstract apiType : string;

  constructor(private readonly http: HttpClient) {
  }

  private getApiUrl() : string {
    const apiUrlOverride = environment["fleetCommandApiUrlOverride"]?.[this.api]?.[this.apiType];
    if(apiUrlOverride) {
      return apiUrlOverride;
    }

    return `${environment.fleetCommandApiUrl}${this.api}${this.apiType}/`;
  }

  delete(endpoint: string, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<Object> {
    return this.http.delete(this.getApiUrl() + endpoint,
      {
        params: options ? options.params : null
      });
  }

  get<T>(endpoint: string, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<T> {
    return this.http.get<T>(this.getApiUrl() + endpoint,
      {
        params: options ? options.params : null
      });
  }

  getFile(endpoint: string, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<HttpResponse<Blob>> {
    return this.http.get(this.getApiUrl() + endpoint,
      {
        params: options ? options.params : null,
        responseType: 'blob',
        observe: 'response'
      });
  }

  post(endpoint: string, body: any | null, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<Object> {
    return this.http.post(this.getApiUrl() + endpoint, body,
      {
        params: options ? options.params : null,
        headers: {
          'Content-Type': 'application/json'
        }
      });
  }

  put(endpoint: string, body: any | null, options?: {
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  }): Observable<Object> {
    return this.http.put(this.getApiUrl() + endpoint, body,
      {
        params: options ? options.params : null,
        headers: {
          'Content-Type': 'application/json'
        }
      });
  }
}
