import { DocumentLibraryService } from './document-library/document-library.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, RouteReuseStrategy } from '@angular/router';
import { AngularEditorModule } from '@kolkov/angular-editor';

//Angular Material 
import { AngularMaterialModule } from '../../angular-material.module'

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { CustomReuseStrategy } from '../components/routing/custom-reuse-strategy.component';

import { LoadingPanelComponent } from './loading-panel/loading-panel.component';
import { QuickSearchMenuComponent } from './quick-search-menu/quick-search-menu.component';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { SortTilesPipe } from '../components/dashboard-stat-center/dashboard-stat-center.component';
import { ImagePipe } from './file-download/image.pipe';
import { ShowDpTabsPipe } from '../safety/dhp-landing-page/dhp-landing-page.component';
import { PhoneUsPipe, NegativeToParensPipe, SafePipe, StringReplaceCharsPipe } from '../components/classes-and-interfaces/classes-and-interfaces.component';
import { ScrollToTopService } from './scroll-to-top-service/scroll-to-top.service';
import { BlobStorageService } from './blob-storage/blob-storage.service';
import { FileDownloadService } from './file-download/file-download.service';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { DataGridExportEmailComponent } from '../components/data-grid-export-email/data-grid-export-email.component';

// DHP Safety components used outside of Safety
import { DHPAddNotesComponent } from '../safety/dhp-add-notes/dhp-add-notes.component';
import { DHPContestMvrComponent } from '../safety/dhp-contest-mvr/dhp-contest-mvr.component';
import { DHPVerifyMvrComponent } from '../safety/dhp-verify-mvr/dhp-verify-mvr.component';
import { DHPAssignPointsComponent } from '../safety/dhp-assign-points/dhp-assign-points.component';
import { DHPMyFollowUpComponent } from '../safety/dhp-my-followup/dhp-my-followup.component';
import { DHPOrderMVRComponent } from '../safety/dhp-order-mvr/dhp-order-mvr.component';
import { DHPLeaveOfAbsenceComponent } from '../safety/dhp-leave-of-absence/dhp-leave-of-absence.component';
import { DHPModalWindowComponent } from '../safety/dhp-modal-window/dhp-modal-window.component';
import { DHPConfirmActionComponent } from '../safety/dhp-confirm-action/dhp-confirm-action.component';
import { DHPViewEmailComponent } from '../safety/dhp-view-email/dhp-view-email.component';
import { DHPEditTrainingDueDateComponent } from '../safety/dhp-edit-training-due-date/dhp-edit-training-due-date.component';
import { DHPTelematicsDetailComponent } from '../safety/dhp-telematics-detail/dhp-telematics-detail.component';
import { SafetyModalWindowComponent } from '../safety/safety-modal-window/safety-modal-window.component';

import { LeaveOfAbsenceComponent } from '../safety/leave-of-absence/leave-of-absence.component';
import { CovSignoffModalComponent } from '../safety/cov-signoff-modal/cov-signoff-modal.component';
import { ConfirmModalComponent } from '../components/confirm-modal/confirm-modal.component';
import { ConfirmModalGenericComponent } from '../components/confirm-modal-generic/confirm-modal-generic.component';
import { CDLVerificationModalComponent } from '../safety/cdl-verification-modal/cdl-verification-modal.component';

// Delivery Contractor
import { DcModalWindowComponent } from '../dc/dc-modal-window/dc-modal-window.component';
import { DcConfirmActionComponent } from '../dc/dc-confirm-action/dc-confirm-action.component';
import { DcLeaveOfAbsenceComponent } from '../dc/dc-leave-of-absence/dc-leave-of-absence.component';
import { DcDriversOnLeaveDialogComponent } from '../dc/dc-drivers-on-leave-dialog/dc-drivers-on-leave-dialog.component';
import { DcProgramsDriverSlotsAddComponent } from '../dc/dc-programs-driver-slots-add/dc-programs-driver-slots-add.component';
import { DcDriverAssignProgramComponent } from '../dc/dc-driver-assign-program/dc-driver-assign-program.component';
import { DcDriverRemoveProgramComponent } from '../dc/dc-driver-remove-program/dc-driver-remove-program.component';

/** Import generic table module */
import { TrainingSummaryExportComponent } from '../safety/training-summary-export/training-summary-export.component';
import { CorporateStructureAddGroupModalComponent } from '../safety/corporate-structure-add-group-modal/corporate-structure-add-group-modal.component';
import { CorporateStructureComponent } from '../safety/corporate-structure/corporate-structure.component';
import { CorporateStructureFolderComponent } from '../safety/corporate-structure-folder/corporate-structure-folder.component';
import { CorporateStructureSelectorFolderComponent } from '../safety/corporate-structure-selector-folder/corporate-structure-selector-folder.component';
import { ExportDriverSelectComponent } from '../safety/export-driver-select/export-driver-select.component';
import { DcCompanySelectorComponent } from '../dc/dc-company-selector/dc-company-selector.component';
import { DocumentUploadAzureComponent } from './document-upload-azure/document-upload-azure.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component'; 
import { DataTableComponent } from '../components/data-table/data-table.component';

import { DQDrivingHistoryVerificationUnsuccessfulReasonComponent, 
  DQDrivingHistoryVerificationUnsuccessfulReasonOtherComponent } from '../safety/dq-driving-history/dq-driving-history.component';
import { DhpMvrCustomRowComponent } from '../safety/dhp-mvr-report/dhp-mvr-custom-row/dhp-mvr-custom-row.component';
import { SecondaryDriverActiveSwitchComponent } from '../safety/safety-additional-drivers-full/safety-additional-drivers-full.component'
import { BubblePaginationDirective } from './bubble-pagination.directive';

@NgModule({
  declarations: [
    LoadingPanelComponent, 
    QuickSearchMenuComponent,
    DocumentUploadComponent,
    ImagePreviewComponent,
    DataGridExportEmailComponent,
    DHPConfirmActionComponent,
    DHPLeaveOfAbsenceComponent,
    DHPModalWindowComponent,
    DHPAddNotesComponent,
    DHPContestMvrComponent,
    DHPVerifyMvrComponent,
    DHPAssignPointsComponent,
    DHPOrderMVRComponent,
    DHPMyFollowUpComponent,
    DHPViewEmailComponent,
    DHPEditTrainingDueDateComponent,
    DHPTelematicsDetailComponent,
    SafetyModalWindowComponent,
    DcModalWindowComponent,
    DcConfirmActionComponent,
    DcLeaveOfAbsenceComponent,
    DcDriversOnLeaveDialogComponent,
    DcProgramsDriverSlotsAddComponent,
    DcDriverAssignProgramComponent,
    DcDriverRemoveProgramComponent,    
    LeaveOfAbsenceComponent,
    CovSignoffModalComponent,
    ConfirmModalComponent,
    ConfirmModalGenericComponent,    
    SortTilesPipe,
      PhoneUsPipe,
      NegativeToParensPipe,
      SafePipe,
        ShowDpTabsPipe,
        StringReplaceCharsPipe,
    ImagePipe,
    TrainingSummaryExportComponent,
    CorporateStructureAddGroupModalComponent,
    CorporateStructureComponent,
    CorporateStructureFolderComponent,
    CorporateStructureSelectorFolderComponent,
    DcCompanySelectorComponent,
    ExportDriverSelectComponent,
    DocumentUploadAzureComponent,
    LoadingSpinnerComponent,
    CDLVerificationModalComponent,
    DataTableComponent,
    DQDrivingHistoryVerificationUnsuccessfulReasonComponent,
    DQDrivingHistoryVerificationUnsuccessfulReasonOtherComponent,
    DhpMvrCustomRowComponent,
    SecondaryDriverActiveSwitchComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AngularEditorModule,
    PdfViewerModule,
    DragulaModule,
    AngularMaterialModule,
    BubblePaginationDirective
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AngularEditorModule,
    DragulaModule,
    LoadingPanelComponent, 
    QuickSearchMenuComponent,
    DocumentUploadComponent,
    ImagePreviewComponent,
    DataGridExportEmailComponent,
    DHPConfirmActionComponent,
    DHPLeaveOfAbsenceComponent,
    DHPModalWindowComponent,
    DHPAddNotesComponent,
    DHPContestMvrComponent,
    DHPVerifyMvrComponent,
    DHPAssignPointsComponent,
    DHPOrderMVRComponent,
    DHPMyFollowUpComponent,
    DHPViewEmailComponent,
    DHPEditTrainingDueDateComponent,
    DHPTelematicsDetailComponent,
    SafetyModalWindowComponent,
    DcModalWindowComponent,
    DcConfirmActionComponent,
    DcLeaveOfAbsenceComponent,
    DcDriversOnLeaveDialogComponent,
    DcProgramsDriverSlotsAddComponent,
    DcDriverAssignProgramComponent,
    DcDriverRemoveProgramComponent,    
    LeaveOfAbsenceComponent,
    CovSignoffModalComponent,
    ConfirmModalComponent,
    ConfirmModalGenericComponent,    
    SortTilesPipe,
    PhoneUsPipe,
    NegativeToParensPipe,
    SafePipe,
    ShowDpTabsPipe,
    StringReplaceCharsPipe,
    ImagePipe,
    TrainingSummaryExportComponent,
    CorporateStructureAddGroupModalComponent,
    CorporateStructureComponent,
    CorporateStructureFolderComponent,
    CorporateStructureSelectorFolderComponent,
    ExportDriverSelectComponent,
    DcCompanySelectorComponent,
    DocumentUploadAzureComponent,
    AngularMaterialModule,
    LoadingSpinnerComponent,
    CDLVerificationModalComponent,
    DataTableComponent,
    BubblePaginationDirective
  ],
  providers: [
    DragulaService,
    ScrollToTopService,
    FileDownloadService,
    DocumentLibraryService,
    BlobStorageService,
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy }
  ],
})
export class SharedModule {
    constructor() {}
}
