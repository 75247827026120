// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  appVersion: require('../../package.json').version,
  production: false,
    //visibilityApiUrl: 'https://visibility-prod-nc-apisvc-01.azurewebsites.net/',
    visibilityApiUrl: 'https://visibility.fleetresponse.com/api/',
    storageUriDocumentLibrary: 'https://vispncazrstg01.blob.core.windows.net/',
    storageUriClaimsDocuments: 'https://fleetresponsestorage.blob.core.windows.net/',
    fleetCommandApiUrl: "https://command.fleetresponse.com/",
    AuthConfiguration: {
        "authority": "https://sso.fleetresponse.com",
        "redirect_uri": "https://visibility.fleetresponse.com/callback",
        "silent_redirect_uri": "https://visibility.fleetresponse.com/silent-refresh.html",
        "client_id": "76A9542A-0D00-42E8-9215-2C482A50CAE5",
        "response_type": "id_token token",
        "scope": "openid profile email fleetsuiteApi claimApi driverApi deliveryContractorApi timeClockApi visibilityUserAdministrationQueryApi",
        "post_logout_redirect_uri": "https://visibility.fleetresponse.com/home",
        "automaticSilentRenew": true,
        "silentRequestTimeout": 10000,
        "onPremApi": "https://api.fleetresponse.com/",
        "userAdminApi": ""
    },
    appInsights: {
        instrumentationKey: '81c075c9-2b10-4f92-9c48-bea367993028'
    },
    launchDarkly: {
      ClientId: '66fe8350bbda2a0850a051ae'
    }
};
