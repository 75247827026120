import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  DriverTasksCommandService,
  DriverTasksQueryService
}  from '../fleet-command-services';
import {
  DhpDocumentValidationReject,
  DhpDocumentValidationDocumentType
} from '../../safety/models';



@Injectable()
export class DhpDocumentService {

  constructor(
    private readonly driverTasksCommandService: DriverTasksCommandService,
    private readonly driverTasksQueryService: DriverTasksQueryService
  )
  { }

  GetPendingDocuments(): Observable<any> {
    return this.driverTasksQueryService.get(
      'DocumentValidation/Documents'
    );
  }

  GetDocument(id: number): Observable<any> {
    return this.driverTasksQueryService.get(
      `DocumentValidation/documents/${id}`
    );
  }

  GetDocumentLookupLists(): Observable<any> {
    return this.driverTasksQueryService.get(
      'DocumentValidation/lookups'
    );
  }

  SaveDocumentApprove(documentId: number): Observable<any> {
    return this.driverTasksCommandService.post(
      `DocumentValidation/documents/${documentId}/approve`, null
    );
  }

  SaveDocumentReject(documentId: number, dhpDocumentValidationReject: DhpDocumentValidationReject): Observable<any> {
    return this.driverTasksCommandService.post(
      `DocumentValidation/documents/${documentId}/reject`,
      JSON.stringify(dhpDocumentValidationReject)
    );
  }

  SaveDocumentRemove(documentId: number): Observable<any> {
    return this.driverTasksCommandService.post(
      `DocumentValidation/documents/${documentId}/remove`, null
    );
  }

  ChangeDocumentType(documentId: number, dhpDocumentValidationDocumentType: DhpDocumentValidationDocumentType): Observable<any> {
    return this.driverTasksCommandService.post(
      `DocumentValidation/documents/${documentId}/changeType`,
      JSON.stringify(dhpDocumentValidationDocumentType)
    );
  }

}
