//Angular
import { Component, OnInit, OnDestroy, Inject, ElementRef, ViewChildren, QueryList, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
//Third Party
import { Subscription } from 'rxjs';
import { MatSelect } from '@angular/material/select';
//Third Party
import { AccidentClaimService } from '../accident-claim/accident-claim-service.component';
import { ClientSelectionService } from '../../components/client-selection-service/client-selection-service.component';
import {
  AccidentClaimRepairApprovalDetail, AccidentClaimRepairApprovalHistory, ApprovalResponseLookup,
  ClientReqTitles, SalvageTitleHolder
} from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { FileDownloadService } from '../../shared/file-download/file-download.service';
import { MatSelectOption } from '../../shared/models/mat-select-option.model';

@Component({
  selector: "app-accident-claim-repair-approval",
  templateUrl: "./accident-claim-repair-approval.component.html",
  styleUrls: ["./accident-claim-repair-approval.component.scss"],
})
export class AccidentClaimRepairApprovalComponent
  implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChildren("selectTitleHolder") selectTitleHolderList: QueryList<MatSelect>;
  selectTitleHolderRef: MatSelect;
  _claimId: number;
  approvalDetail: AccidentClaimRepairApprovalDetail;
  approvalHistory: Array<AccidentClaimRepairApprovalHistory>;
  approvalResponses: Array<ApprovalResponseLookup>;
  titleHolders: Array<SalvageTitleHolder>;
  clientReqTitles: ClientReqTitles;
  _routeSub: Subscription;
  _baseUrl: string;
  elRef: ElementRef;

  clientResponseId: number;
  comments: string;
  titleHolderId: number;
  titleHolderOptions: Array<MatSelectOption> = [];
  filteredTitleHolderOptions: Array<MatSelectOption>;
  verifyTitleHolder: boolean;
  buyItNowOption: number;
  buyItNowAmount: number;
  estimateAmount: number;
  req6: string;
  req7: string;
  client: string;
  userEmailAddress: string;
  totalEstimateAmount: number;

  showTitleHolders = false;
  showBuyItNowSection = false;
  showBuyItNowOptions = false;
  disableApproval = false;

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly router: Router,
    @Inject("BASE_URL") baseUrl: string,
    private readonly accidentClaimService: AccidentClaimService,
    private readonly clientService: ClientSelectionService,
    private readonly errorService: ErrorModalService,
    private readonly fileDownloadService: FileDownloadService
  ) {
    this._baseUrl = baseUrl;
  }

  printPage(): void {
    window.print();
  }

  ngOnInit(): void {
    this._routeSub = this._route.params.subscribe((params) => {
      this._claimId = +params["claimId"];

      this.accidentClaimService
        .getRepairApprovalDetail(this._claimId)
        .subscribe({
          next: (data) => {
            this.approvalDetail = data as AccidentClaimRepairApprovalDetail;

            this.client = (this.approvalDetail.client ? this.approvalDetail.client : null);
            this.estimateAmount = (this.approvalDetail.estimateAmount ? this.approvalDetail.estimateAmount : null);
            this.req6 = (this.approvalDetail.req6 ? this.approvalDetail.req6 : null);
            this.req7 = (this.approvalDetail.req7 ? this.approvalDetail.req7 : null);
            this.userEmailAddress = (this.approvalDetail.userEmailAddress ? this.approvalDetail.userEmailAddress : null);
            this.totalEstimateAmount = (this.approvalDetail.totalEstimateAmount ? this.approvalDetail.totalEstimateAmount : null);

            this.validateCharterApproval(this.client);

            if (
              this.approvalDetail &&
              this.approvalDetail.clientActionLookupId
            ) {
              this.accidentClaimService
                .getRepairApprovalResponses(
                  this.approvalDetail.clientActionLookupId
                )
                .subscribe({
                  next: (lookup) => {
                    this.approvalResponses =
                      lookup as Array<ApprovalResponseLookup>;
                  },
                  error: (err: HttpErrorResponse) => {
                    console.log(err.error.toString());
                  }
                });
            }

            if (this.approvalDetail && this.approvalDetail.client) {
              this.clientService
                .getClientReqTitles(this.approvalDetail.client)
                .subscribe({
                  next: (client) => {
                    this.clientReqTitles = client as ClientReqTitles;
                  },
                  error: (err: HttpErrorResponse) => {
                    console.log(err.error.toString());
                  }
                });

              this.accidentClaimService
                .getSalvageTitleHolders(this.approvalDetail.client)
                .subscribe({
                  next: (titleHolder) => {
                    this.titleHolders =
                      titleHolder as Array<SalvageTitleHolder>;

                    this.titleHolders.forEach(
                      (item: SalvageTitleHolder) => {
                        const tempobj: MatSelectOption = {
                          value: item.titleHolderId,
                          label: item.titleHolderName.toString(),
                        };
                        this.titleHolderOptions.push(tempobj);
                      }
                    );
                    this.filteredTitleHolderOptions = this.titleHolderOptions;
                  },
                  error: (err: HttpErrorResponse) => {
                    console.log(err.error.toString());
                  }
                });
            }
          },
          error: (err: HttpErrorResponse) => {
            console.log(err.error.toString());
          }
        });

      this.accidentClaimService
        .getRepairApprovalHistory(this._claimId)
        .subscribe({
          next: (data) => {
            this.approvalHistory =
              data as Array<AccidentClaimRepairApprovalHistory>;
          },
          error: (err: HttpErrorResponse) => {
            console.log(err.error.toString());
          }
        });
    });
  }

  ngAfterViewChecked(): void {
    if (!this.selectTitleHolderRef && this.selectTitleHolderList?.first) {
      this.selectTitleHolderRef = this.selectTitleHolderList.first;
      this.selectTitleHolderRef._elementRef.nativeElement.addEventListener(
        "keydown",
        (event) => {
          if (event.code === "Space") {
            event.preventDefault();
          }
        }
      );
    }
  }

  ngOnDestroy() {
    this._routeSub.unsubscribe();
  }

  filterOptions(value: string) {
    this.filteredTitleHolderOptions = this.titleHolderOptions.filter((option) =>
      option.label.toLowerCase().startsWith(value)
    );
  }

  public onResponseChange(): void {
    this.showTitleHolders = this.clientResponseId === 18;
    this.showBuyItNowSection =
      this.clientResponseId === 18 ||
      this.clientResponseId === 21 ||
      this.clientResponseId === 25;
    this.showBuyItNowOptions =
      this.clientResponseId === 18 || this.clientResponseId === 21;

    if (!this.showTitleHolders) {
      this.titleHolderId = undefined;
      this.verifyTitleHolder = undefined;
    }
    if (!this.showBuyItNowSection) {
      this.buyItNowAmount = undefined;
    }
    if (!this.showBuyItNowOptions) {
      this.buyItNowOption = undefined;
    }
  }

  public submitClientApproval(): void {
    if (this.clientResponseId === undefined) {
      this.errorService.setErrorObject({
        message: "Client Response is required",
      });
      return;
    }

    if (
      this.approvalResponses.filter(
        (x) => x.responseLookupId === this.clientResponseId
      )[0].isCommentMandatory &&
      (this.comments === undefined || this.comments === "")
    ) {
      this.errorService.setErrorObject({ message: "Comments are required" });
      return;
    }

    this.accidentClaimService
      .updateRepairClientApproval(
        this.approvalDetail.repairClientApprovalId,
        this._claimId,
        this.clientResponseId,
        this.approvalDetail.vin,
        this.titleHolderId,
        this.buyItNowAmount,
        this.comments
      )
      .subscribe({
        next: () => {
          this.router.navigate(["/accident/claim/" + this._claimId]);
        },
        error: (err: HttpErrorResponse) => {
          if (err.error) {
            this.errorService.setErrorObject(err.error);
          }
        }
      });
  }

  public resetClientApproval(): void {
    this.showTitleHolders = false;
    this.showBuyItNowSection = false;
    this.showBuyItNowOptions = false;

    this.clientResponseId = undefined;
    this.comments = undefined;
    this.titleHolderId = undefined;
    this.verifyTitleHolder = undefined;
    this.buyItNowAmount = undefined;
    this.buyItNowOption = undefined;
  }

  public getDownloadUrl(data: AccidentClaimRepairApprovalDetail): void {
    const documentByIdUrl = "api/Document/GetDocumentById?DocumentGuid=";
    this.fileDownloadService.saveFile(
      this._baseUrl + documentByIdUrl
      +
      data.documentIdentifier
    );
  }

  public exportToPdf() {
    const repairApprovalPdfUrl = `api/Claim/GetRepairApprovalPdf?claimId=${this._claimId}&client`
    this.fileDownloadService.saveFile(
      this._baseUrl + repairApprovalPdfUrl + repairApprovalPdfUrl +
      this.approvalDetail.client
    );
  }

  validateCharterApproval(client: string) {
    this.disableApproval = false;
    if (client === "CCOM1") {
      if (this.totalEstimateAmount >= 2500 && this.totalEstimateAmount <= 5000) {
        if (this.req6 === this.userEmailAddress) {
          this.disableApproval = true;
        }
      }
      if (this.totalEstimateAmount > 5000) {
        if (this.req6 === this.userEmailAddress || this.req7 === this.userEmailAddress) {
          this.disableApproval = true;
        }
      }
    }
  }

}
