import { Service } from './service.model';

export class ServiceTraining extends Service {

  courseIds?: number[];
  passRates?: number[];
  dueDate?: Date;
  trainingAssignmentTypeId: number;

  constructor() {
      super('ServiceTraining');
  }
}
