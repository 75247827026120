<div class="modal-content" [formGroup]="form">
  <div class="modal-header">

    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="cancel()">
      <span aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title w-100" id="labelDqRejectModal">Edit Document</h4>
  </div>

  <div id="modalBody" class="modal-body">
    <div class="details">
      <div class="row">
        <div class="col-md-2 align-both">Description:</div>
        <div class="col-md-10 align-both right">
          {{ documentData.documentDescription }}
        </div>
      </div>

      <div class="row">
        <div class="col-md-2 align-both">File Name:</div>
        <div class="col-md-10 align-both right">
          {{ documentData.documentFileName }}
        </div>
      </div>

      <div class="row">
        <div class="col-md-2 align-both">Document Type:</div>
        <div class="col-md-10 align-both right">
          {{ documentData.documentType }}
        </div>
      </div>

      <div class="row">
        <div class="col-md-2 align-both">Uploaded:</div>
        <div class="col-md-10 align-both right">
          {{ documentData.documentUploadedDate | date : "M/d/yyyy" }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Document Type</mat-label>
          <mat-select
          formControlName="documentTypeId"
            (selectionChange)="handleDocumentTypeChange($event)">
            <mat-option
              *ngFor="let option of documentTypes"
              [value]="option.id">
              {{ option.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" [disabled]="form.invalid" (click)="openConfirmationModal()">
      Change Type
    </button>
    <button class="btn btn-primary" (click)="cancel()">Cancel</button>
  </div>
</div>
