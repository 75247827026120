import { Service } from './service.model';

export class ServicePolicySignoff extends Service {

  dueDate?: Date;

  constructor() {
      super('ServicePolicySignoff');
  }
}
