import { Service } from './service.model';

export class ServiceDriverLicenseVerification extends Service {

  dueDate?: Date;

  constructor() {
      super('ServiceDriverLicenseVerification');
  }
}
