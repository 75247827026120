import { NgModule, NO_ERRORS_SCHEMA, APP_INITIALIZER } from '@angular/core';

import { RouterModule, RunGuardsAndResolvers, RouteReuseStrategy } from '@angular/router';

import { SharedModule } from '../shared/shared.module';

import { AuthService } from '../components/auth/auth.service';
import { AuthGuard } from '../components/auth/auth.guard';

import { DcDriverProfileComponent } from './dc-driver-profile/dc-driver-profile.component';
import { DcDriverEditComponent } from './dc-driver-edit/dc-driver-edit.component';
import { DcDriverInfoComponent } from './dc-driver-info/dc-driver-info.component';
import { DcDriverAssignedProgramsComponent } from './dc-driver-assigned-programs/dc-driver-assigned-programs.component';
import { DcDriverTrainingComponent } from './dc-driver-training/dc-driver-training.component';
import { DcDriverNotesDocsComponent } from './dc-driver-notes-docs/dc-driver-notes-docs.component';
import { DcDriverProfileService } from './dc-driver-profile/dc-driver-profile-service.component';
import { DcConfirmActionService } from './dc-confirm-action/dc-confirm-action-service.component';
import { DcModalWindowService } from './dc-modal-window/dc-modal-window-service.component';
import { DcProgramsSummaryComponent } from './dc-programs-summary/dc-programs-summary.component'; 
import { DcProgramsDriverSlotsComponent } from './dc-programs-driver-slots/dc-programs-driver-slots.component';
import { DcProgramsDriverSlotsDetailComponent } from './dc-programs-driver-slots-detail/dc-programs-driver-slots-detail.component';
import { DcDocumentLibraryComponent } from './dc-document-library/dc-document-library.component';
import { DcDocumentLibraryFolderComponent } from './dc-document-library-folder/dc-document-library-folder.component';
import { DcFilterComponent } from './dc-filter/dc-filter.component';

import { environment } from '../../environments/environment';
import { DcDriversOnLeaveReportComponent } from './dc-drivers-on-leave-report/dc-drivers-on-leave-report.component';
import { MvrRetrieveComponent, PdfType } from '../safety/mvr-retrieve/mvr-retrieve.component';
import { DcTrainingSummaryExportComponent } from './dc-training-summary-export/dc-training-summary-export.component';
import { CorporateStructureComponent } from '../safety/corporate-structure/corporate-structure.component';

export function loadAuthConfig(authService: AuthService) {
  console.log('APP_INITIALIZER STARTING');
  return () => authService.configureAuthentication(environment.visibilityApiUrl + `api/Configuration`);
}

@NgModule({
  declarations: [
    DcDriverProfileComponent,
    DcDriverEditComponent,
    DcDriverInfoComponent,
    DcDriverAssignedProgramsComponent,
    DcDriverTrainingComponent,
    DcDriverNotesDocsComponent,
    DcDriversOnLeaveReportComponent,
    DcProgramsSummaryComponent,
    DcProgramsDriverSlotsComponent,
    DcProgramsDriverSlotsDetailComponent,
    DcDocumentLibraryComponent,
    DcDocumentLibraryFolderComponent,
    DcTrainingSummaryExportComponent,
    DcFilterComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild([

      { path: 'dc/driverprofile/:id', component: DcDriverProfileComponent, canActivate: [AuthGuard] },
      { path: 'dc/programssummary', component: DcProgramsSummaryComponent, canActivate: [AuthGuard] },
      { path: 'dc/documentlibrary', component: DcDocumentLibraryComponent, canActivate: [AuthGuard] },
      { path: 'dc/driveredit/:id', component: DcDriverEditComponent, canActivate: [AuthGuard] },
      { path: 'dc/drivernew', component: DcDriverEditComponent, canActivate: [AuthGuard] },
      { path: 'dc/driverentry', component: DcProgramsDriverSlotsComponent, canActivate: [AuthGuard] },
      { path: 'dc/driversonleave', component: DcDriversOnLeaveReportComponent, canActivate: [AuthGuard] },
      { path: 'dc/driversonleave/:id', component: DcDriversOnLeaveReportComponent, canActivate: [AuthGuard] },
      { path: 'dc/trainingsummaryexport', component: DcTrainingSummaryExportComponent, canActivate: [AuthGuard], data: { Lob: 7 } },
      { path: 'dc/trainingretrieve', component: MvrRetrieveComponent, canActivate: [AuthGuard], data: { pdfType: PdfType.Training, Lob: 7 } },
      { path: 'dc/corpstructure', component: CorporateStructureComponent, canActivate: [AuthGuard], data: { shouldReuse: false, Lob: 7 } },
      { path: 'dc/filter', component: DcFilterComponent, canActivate: [AuthGuard] },
    ])
  ],
  providers: [
    DcDriverProfileService,
    DcConfirmActionService,
    DcModalWindowService
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class DcModule {
  constructor(private authService: AuthService) {
    console.log('DC MODULE STARTING');
  }
}

