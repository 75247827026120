import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService implements OnDestroy {

  client: LaunchDarkly.LDClient;

  constructor() {}

  async ngOnDestroy() {
    await this.client.close();
  }

  evaluateClientVariation(variation: Array<string>, client: string, featureFlag = ''): boolean {
    let result;

    if (variation[0] === '*') {
      // '*' allows any client to use the new batch API
      result = true;
    } else {
      result = variation
        .map((v: string) => v.toLowerCase())
        .includes(client.toLowerCase());
    }

    this.logMessage(`-- CLIENT: ${client.toLowerCase()} | FLAG: ${featureFlag} | BATCH:`, result);

    return result;
  }

  initialize(): void {
    const options: LaunchDarkly.LDOptions = {
      application: {
        id: 'visibility-frontend',
        version: environment.appVersion,
      },
    };
    const context = {
      kind: 'organization',
      key: 'context-key',
    } as LaunchDarkly.LDContext;

    this.client = LaunchDarkly.initialize(
      environment.launchDarkly.ClientId,
      context,
      options
    );
  }

  getFlag(flagKey: string, defaultValue: LaunchDarkly.LDFlagValue = false): Observable<LaunchDarkly.LDFlagValue> {
    const fetchFlag = new Subject<void>();

    this.client.waitUntilReady().then(() => {
      this.client.on(`change:${flagKey}`, () => {
        fetchFlag.next();
      });
      this.client.waitUntilReady().then(() => {
        fetchFlag.next();
      });
    });

    return fetchFlag.pipe(
      map(() => {
        return this.client.variation(flagKey, defaultValue);
      })
    );
  }

  logMessage(message: string, result = false): void {
    const d = {
      message: `[LaunchDarkly] ${message}`,
      default: 'background: black; color:#7DF9FF;',
      nextGen: 'background: #42f57b; color:black;',
      system:  result ? ' DHP Next Gen' : 'Legacy',
      get syscolor() {
        return  result ? this.nextGen : this.default;
      }
    }

    console.log(`%c${d.message} %c${d.system} `, `${d.default}`, `${d.syscolor}`);
  }
}
