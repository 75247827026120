<div class="quickSearchContainer">
  <quick-search-menu></quick-search-menu>
</div>

<div>
  <H3>DHP Document Validation</H3>

  <div>
    <app-loading-panel *ngIf="!configObject"></app-loading-panel>

    <div *ngIf="configObject">
      <app-data-table
        #myTable
        [columns]="configObject.fields"
        [data]="configObject.data"
        noRecordsLabel="No Review Information"
        [showPagination]="true"
        [customPageSize]="10"
        (triggerSortChange)="sortChange($event)"
      ></app-data-table>
    </div>

    <div id="reportFooter">
      <div class="left"> Fleet Response Confidential</div>
      <div class="right"> Report run {{today | date :'short' }}</div>
    </div>

  </div>

</div>
