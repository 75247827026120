//angular libraries
import { Component, OnInit, Inject } from '@angular/core';

import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

//third party libraries
import { Observable } from 'rxjs';
import { formatDate } from 'ngx-bootstrap/chronos';

//project imports
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { DhpDocumentService } from '../../services/dhp-document-service/dhp-document.service';

import { UtilityTimeService } from '../../services/utility-services/utility-time.service';

@Component({
  selector: 'app-dhp-document-validation-grid',
  templateUrl: './dhp-document-validation-grid.component.html',
  styleUrls: ['./dhp-document-validation-grid.component.scss']
})

export class DhpDocumentValidationGridComponent implements OnInit {
  public configObject: any;
  clientSelected: string;
  gtOptions: any = {};
  columns = [];
  rowsRaw: Array<any>;
  rows: Array<any>;
  today: any;
  baseUrl: string;
  lineOfBusinessId: number;
  SortDisplayField: string;
  SortField: string;
  SortDir: string;

  public config: any = {
    paging: true,
    sorting: { columns: this.columns },
    filtering: {}
  };

  constructor(
    @Inject('BASE_URL') baseUrl: string,
    private readonly router: Router,
    private readonly loadingSpinnerService: LoadingSpinnerService,
    private readonly dhpDocumentService: DhpDocumentService,
    private readonly errorService: ErrorModalService
  ) {
    this.baseUrl = baseUrl;
    this.today = Date.now();
  }

  ngOnInit(): void {

    this.loadingSpinnerService.show();

    this.getGridData().subscribe({
      next: (data) => {
        this.addCalculatedValuesToData(data);

        // initial sort will keep multiple docs for same driver consecutive in list
        data = data.sort((a,b) => a.sortDate - b.sortDate);

        const rows = (data).map(x => {
          return {
            action: 'Review',
            documentId: x.documentId,
            documentUploadDate: x.documentUploadDate,
            documentType: x.documentType,
            clientCode: x.clientCode,
            driverLicenseState: x.driverLicenseState,
            driverName: `${x.driverLastName}, ${x.driverFirstName}`,
            driverDOB: x.driverDOB,
            driverType: x.driverType,
            dhpRepName: `${x.dhpRepLastName}, ${x.dhpRepFirstName}`,
            // props below not to be displayed in grid
            hasMultipleDocs: x.hasMultipleDocs,
          }
        });
        this.setupGrid(rows);
        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.setErrorObject(err.error);
        this.loadingSpinnerService.hide();
      }
    });
  }


  setSortDisplayField(field: any): void {
    if (field) {
      this.SortDisplayField = field.name;
    }
  }

  sortChange($event): void {
    if (!$event?.active) {
      this.resetSort();
    } else {
      this.SortField = $event?.active || null;
      this.SortDir = $event?.direction?.toUpperCase() || null;
      this.setSortDisplayField(this.configObject.fields.find(x => x.objectKey === this.SortField));
    }
  }

  trigger($event): void {
    if ($event.name === 'gt-sorting-applied') {
      if ($event.value && $event.value[0] !== '$$gtInitialRowIndex') {
        if (($event.value[0] as string).startsWith('-')) {
          this.SortField = ($event.value[0] as string).slice(1);
          this.SortDir = 'DESC';
        } else {
          this.SortField = $event.value[0];
          this.SortDir = 'ASC';
        }
        this.setSortDisplayField(this.configObject.fields.find(x => x.objectKey === this.SortField));
      }
    } else {
      this.resetSort();
    }
  }

  private addCalculatedValuesToData(data: any): void {
    // group by driverId
    const groupByDriverId = data.reduce((acc, x) => {
      if(acc[x.driverId] === undefined) {
         acc[x.driverId] = [];
      }
      acc[x.driverId].push(x);
      return acc;
    }, {});

    for(const prop in groupByDriverId) {
      const docs = groupByDriverId[prop];
      for(const doc of docs) {
        // sort date for the grid for when there are multiple docs to keep them together
        // the first element should be the earliest due to how API data is sorted
        doc.sortDate = new Date(docs[0].documentUploadDate);
        // check if has multiple docs for displaying alert on grid
        doc.hasMultipleDocs = docs.length > 1;
      }
    }
  }

  private getGridData(): Observable<any> {
    try {
      return this.dhpDocumentService.GetPendingDocuments();
    }
    catch (err) {
      console.log(err);
      return new Observable<null>();
    }
  }

  private setupGrid(data): void {

    this.rows = data;
    this.rowsRaw = data;

    this.columns = [
      { name: 'Action', prop: 'action', title: 'Action', visible: true, sort: '' },
      { name: 'Upload Date', prop: 'documentUploadDate', title: 'Upload Date', dataType: 'date', visible: true, sort: 'enable' },
      { name: 'Document Type', prop: 'documentType', title: 'Document Type', visible: true, sort: 'enable' },
      { name: 'Client', prop: 'clientCode', title: 'Client', visible: true, sort: 'enable' },
      { name: 'State', prop: 'driverLicenseState', title: 'State', visible: true, sort: 'enable' },
      { name: 'Driver Name', prop: 'driverName', title: 'Driver Name', visible: true, sort: 'enable' },
      { name: 'Driver Type', prop: 'driverType', title: 'Driver Type', visible: true, sort: 'enable' },
      { name: 'DHP Rep', prop: 'dhpRepName', title: 'DHP Rep', visible: true, sort: 'enable' }
    ];


    const fields = [];

    this.columns.forEach(s => {

      if (s.prop.toLowerCase() === 'action') {
        fields.push({
          name: s.title,
          objectKey: s.prop,
          columnClass: 'clickable',
          click: row => {
            this.router.navigate([
              `safety/dhpdocumentvalidationreview/${row['documentId']}`
            ]);
          }
        });
      } else {
        fields.push({
          name: s.title,
          objectKey: s.prop,
          rowClass: (row) => {
            const driverType = row['driverType'];
            return driverType === 'Non-employee' ? 'non-employee' : '';
          },
          render: row => {
            if (s.dataType === 'currency') {
              if (row[s.prop] >= 0) {
                return `$${row[s.prop]}`;
              } else {
                return `($${(row[s.prop] * -1)})`;
              }
            } else if (s.dataType === 'date' && row[s.prop]) {
              try {
                return formatDate(new Date(row[s.prop]), 'MM/DD/YYYY hh:mm:ss A');
              }
              catch (err) {
                return row[s.prop];
              }
            } else {
              return row[s.prop];
            }
          }
        });
      }
    });

    const settings = this.columns.map(function (s) {
      return {
        objectKey: s.prop,
        sort: s.sort
      };
    }).filter(x => x.objectKey.toLowerCase() !== 'driverid');
    fields[0].rowClass = (row, col) => {
      const showAlert: boolean = UtilityTimeService.isDaysDifferenceGtOrEq90(new Date(row.documentUploadDate)) ||
                                 UtilityTimeService.isAgeLessThan18(new Date(row.driverDOB ?? new Date())) ||
                                 row.hasMultipleDocs;

      return showAlert ? 'dhp-document-validation-grid-alert' : '';
    };
    this.configObject = {
      settings: settings,
      fields: fields,
      data: data
    };
  }

  private resetSort(): void {
    this.SortDir = '';
    this.SortField = '';
    this.SortDisplayField = '';
  }
}
