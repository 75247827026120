//Angular
import { Component, Input, OnInit, OnChanges, OnDestroy, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
//Third Party
import { Observable, Subscription, forkJoin, zip, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
//App
import {
  DriverProfile, UserRightsInfo, DriverExtendedInfo, DriverActionMenuLabel,
  DriverActionMenuItem, DriverNotes, DriverDocuments, MVRHistory, MVRRecordsCurrent,
  DriverClientSettings
} from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { DriverHistoryProfileService } from '../dhp-landing-page/dhp-landing-page-service.component';
import { OnPremDriverService } from '../../components/on-prem-service/on-prem-driver-service.component';
import { ErrorModalService } from "../../components/error-modal/error-modal-service.component";
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';

/**
 * this is the client-side controller for the safety - additional drivers not full panel
 */
@Component({
  selector: 'safety-additional-drivers-not-full',
  templateUrl: './safety-additional-drivers-not-full.component.html',
  styleUrls: ['./safety-additional-drivers-not-full.component.css']
})

export class SafetyAdditionalDriversNotFullComponent implements OnInit, OnChanges, OnDestroy {
  @Input() primaryDriverProfile: DriverProfile;
  @Input() _userRights: Array<UserRightsInfo>;
  @Input() secondaryDrivers: Array<SecondaryDriverProfile>;
  @Input() arrReqFields: Array<DriverExtendedInfo>;
  @Input() allowMultipleNonEmployees: boolean;
  @Input() clientSettings: DriverClientSettings;
  canAddNewDriver: boolean = false;

  refreshAllInfoSub: Subscription;
  refreshBaseInfoSub: Subscription;
  refreshNotesInfoSub: Subscription;
  refreshDocumentsInfoSub: Subscription;
  refreshCurrentMVRInfoSub: Subscription;
  refreshMVRHistoryInfoSub: Subscription;

  // for building Action Items and Driver Status actions
  actionMenuObjectArray: any = [];

  constructor(private router: Router,
    private renderer: Renderer2,
    private onPremService: OnPremDriverService,
    private readonly loadingSpinnerService: LoadingSpinnerService,
    private readonly errorService: ErrorModalService,
    private readonly driverHistoryProfileService: DriverHistoryProfileService) { }

  ngOnInit() {
    this.calcCanAddNewDriver();

    // subscribe to alerts from modals that ALL data needs to be refreshed
    this.refreshAllInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileAllData$.subscribe({
      next: (data) => {
        // dhpRefreshDriverProfileData will be true if refresh needed
        const secondaryDriver = this.findSecondaryDriverById(data);
        if (secondaryDriver && secondaryDriver.collectionDataLoaded) {
          // update all profile data
          this.getAllSecondaryDriverProfileData(secondaryDriver);
          // set action flag back to false
          this.driverHistoryProfileService.setRefreshDriverProfileAllData(null);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.handleError(err);
      }
    }
    );

    // subscribe to alerts from modals that BASE data needs to be refreshed
    this.refreshBaseInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileBaseData$.subscribe({
      next: (data) => {
        // dhpRefreshDriverProfileData will be true if refresh needed
        const secondaryDriver = this.findSecondaryDriverById(data);
        if (secondaryDriver) {
          const arrObs: Array<Observable<Object>> = [
            this.driverHistoryProfileService.getDriverHistoryProfileBaseData(secondaryDriver?.driverId?.toString()),
            this.getActionItemMenuData(secondaryDriver?.driverId?.toString())
          ];
          this.loadingSpinnerService.show();
          forkJoin(arrObs).subscribe({
            next: (profile) => {
              this.setDriverBaseInfo(secondaryDriver, profile[0] as SecondaryDriverProfile);
              secondaryDriver.actionMenuObjectArray = this.getActionMenuObjectArray(profile[1]);
              this.loadingSpinnerService.hide();
            },
            error: (err: HttpErrorResponse) => {
              this.handleError(err);
            }
          });
          // set action flag back to false
          this.driverHistoryProfileService.setRefreshDriverProfileBaseData(null);
        }

      },
      error: (err: HttpErrorResponse) => {
        this.handleError(err);
      }
    });

    this.refreshNotesInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileNotesData$.subscribe({
      next: (data) => {
        // will be true if refresh needed
        const secondaryDriver = this.findSecondaryDriverById(data);
        if (secondaryDriver && secondaryDriver.collectionDataLoaded) {
          this.loadingSpinnerService.show();
          this.driverHistoryProfileService.getDriverHistoryProfileNotesData(secondaryDriver.driverId.toString()).subscribe({
            next: (profile) => {
              this.setDriverNotesInfo(profile, secondaryDriver);
              this.loadingSpinnerService.hide();
            },
            error: (err: HttpErrorResponse) => {
              this.handleError(err);
            }
          });
          // set action flag back to false
          this.driverHistoryProfileService.setRefreshDriverProfileNotesData(null);
        }

      },
      error: (err: HttpErrorResponse) => {
        this.handleError(err);
      }
    });

    this.refreshDocumentsInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileDocumentsData$.subscribe({
      next: (data) => {
        // will be true if refresh needed
        const secondaryDriver = this.findSecondaryDriverById(data);
        if (secondaryDriver && secondaryDriver.collectionDataLoaded) {
          this.loadingSpinnerService.show();
          this.driverHistoryProfileService.getDriverHistoryProfileDocumentsData(secondaryDriver.driverId.toString()).subscribe({
            next: (profile) => {
              this.setDriverDocumentsInfo(profile, secondaryDriver);
              this.loadingSpinnerService.hide();
            },
            error: (err: HttpErrorResponse) => {
              this.handleError(err);
            }
          });
          // set action flag back to false
          this.driverHistoryProfileService.setRefreshDriverProfileDocumentsData(null);
        }

      },
      error: (err: HttpErrorResponse) => {
        this.handleError(err);
      }
    });

    this.refreshCurrentMVRInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileCurrentMVRData$.subscribe({
      next: (data) => {
        // will be true if refresh needed
        const secondaryDriver = this.findSecondaryDriverById(data);
        if (secondaryDriver && secondaryDriver.collectionDataLoaded) {
          // get mvr current and mvr violations
          this.loadingSpinnerService.show();
          this.driverHistoryProfileService.getDriverHistoryProfileCurrentMVRData(secondaryDriver.driverId.toString()).subscribe({
            next: (profile) => {
              this.setDriverCurrentMVRInfo(profile, secondaryDriver);
              this.loadingSpinnerService.hide();
            },
            error: (err: HttpErrorResponse) => {
              this.handleError(err);
            }
          });
          // set action flag back to false
          this.driverHistoryProfileService.setRefreshDriverProfileCurrentMVRData(null);
        }

      },
      error: (err: HttpErrorResponse) => {
        this.handleError(err);
      }
    });

    this.refreshMVRHistoryInfoSub = this.driverHistoryProfileService.dhpRefreshDriverProfileMVRHistoryData$.subscribe({
      next: (data) => {
        // will be true if refresh needed
        const secondaryDriver = this.findSecondaryDriverById(data);
        if (secondaryDriver && secondaryDriver.collectionDataLoaded) {
          this.loadingSpinnerService.show();
          this.driverHistoryProfileService.getDriverHistoryProfileMVRHistoryData(secondaryDriver.driverId.toString()).subscribe({
            next: (profile) => {
              this.setDriverMVRHistoryInfo(profile, secondaryDriver);
              this.loadingSpinnerService.hide();
            },
            error: (err: HttpErrorResponse) => {
              this.handleError(err);
            }
          });
          // set action flag back to false
          this.driverHistoryProfileService.setRefreshDriverProfileMVRViolationsData(null);
        }

      },
      error: (err: HttpErrorResponse) => {
        this.handleError(err);
      }
    });

  }

  ngOnChanges() {
    this.calcCanAddNewDriver();
  }

  private getActionItemMenuLabelData() {
    return this.driverHistoryProfileService.getDriverHistoryProfileActionMenuLabels(false);
  }

  private getActionItemMenuData(driverId: string) {
    // gets action item menu info, first labels then items
    // that are applicable to the current driver
    return this.getActionItemMenuLabelData().pipe(
      switchMap(res => {
        // get driver items after label data, then
        // combine into on observable

        return zip(
          res ? of(res) : of([]),
          res ? this.driverHistoryProfileService.getDriverHistoryProfileActionMenuItems(driverId, false) : of([])
        );
      })
    );
  }

  private getActionMenuObjectArray(menuData: any): Array<any> {
    const arrCategory: Array<DriverActionMenuLabel> = menuData[0] as Array<DriverActionMenuLabel>;
    const arrItem: Array<DriverActionMenuItem> = menuData[1] as Array<DriverActionMenuItem>;

    const arrObject = [];

    arrCategory.forEach(c => {

      const label = c.description;
      const catId = c.id;
      const items = arrItem.filter(i => (i.secondaryId === c.id));
      arrObject.push({ catId: catId, label: label, items: items });

    })
    return arrObject.reverse();
  }

  actionMenuItemClick(driverProfile: SecondaryDriverProfile, itemId: number) {
    switch (itemId) {
      case 20:
        this.openEmployeeDriverForm(driverProfile, null);
        break;
      case 21:
        this.notifyModal('Reset Password', 'resetPassword', '180px;', 'sm',
          { driverId: driverProfile.driverId }
        );
        break;
      case 22:
        this.notifyModal('Request MVR', 'requestMVRReport', '180px;', 'sm',
          { driverId: driverProfile.driverId, client: driverProfile.clientCode, isEmployee: driverProfile.isEmployee }
        );
        break;
      case 23:
        this.notifyModal('Terminate Driver', 'terminateDriver', '180px;', 'sm',
          { driverId: driverProfile.driverId, primaryDriverId: driverProfile.primaryDriverId, isSecondaryDriver: true }
        );
        break;
      case 31:
        this.notifyModal('Assign Points', 'assignPoints', '580px;', 'lg',
          { driverId: driverProfile.driverId }
        );
        break;
    }

  }

  // notifies the modal of changes
  private notifyModal(title: string, message: string, modalHeight: string, modalWidth: string, modalInput?: any) {
    this.driverHistoryProfileService.notifyModal(title, message, modalHeight, modalWidth, modalInput);
  }

  toggleIsActive(driverProfile: SecondaryDriverProfile, driverId: number, isActive: boolean) {
    driverProfile.isActive = isActive;
    const ep = (isActive ? 'status/reactivate/' : 'status/terminate/');

    this.loadingSpinnerService.show();
    this.onPremService.put(ep + driverId.toString(), null).subscribe({
      next: (data) => {
        // refresh all secondary driver data in primary driver profile
        this.driverHistoryProfileService.setRefreshDriverProfileSecondaryDriverData(driverProfile.primaryDriverId);
        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        this.handleError(err);
      }
    });

  }

  toggleDriver(driverProfile: SecondaryDriverProfile, index: number, e: any) {
    if (driverProfile.isActive && !driverProfile.collectionDataLoaded) {
      // load driver collection data if not already loaded
      this.getAllSecondaryDriverProfileData(driverProfile);
    }
  }

  private getAllSecondaryDriverProfileData(driverProfile: SecondaryDriverProfile) {
    this.loadingSpinnerService.show();
    this.getActionItemMenuData(driverProfile.driverId.toString()).subscribe({
      next: (data) => {
        const actionItemInfo = data;
        driverProfile.actionMenuObjectArray = this.getActionMenuObjectArray(actionItemInfo);
        driverProfile.collectionDataLoaded = true;
        this.loadingSpinnerService.hide();
      },
      error: (err: HttpErrorResponse) => {
        this.handleError(err);
      }
    });
    this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileCurrentMVRData(driverProfile.driverId.toString()), driverProfile, this.setDriverCurrentMVRInfo);
    this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileMVRHistoryData(driverProfile.driverId.toString()), driverProfile, this.setDriverMVRHistoryInfo);
    this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileNotesData(driverProfile.driverId.toString()), driverProfile, this.setDriverNotesInfo);
    this.loadData(this.driverHistoryProfileService.getDriverHistoryProfileDocumentsData(driverProfile.driverId.toString()), driverProfile, this.setDriverDocumentsInfo);
  }

  private loadData(obs: Observable<Object>, driverProfile: SecondaryDriverProfile, callback: (data: any, driverProfile: SecondaryDriverProfile) => void) {
    obs.subscribe({
      next: (data) => {
        if (callback) {
          callback(data, driverProfile);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.handleError(err);
      }
    });
  }

  private readonly setDriverCurrentMVRInfo = (function (data: MVRRecordsCurrent, driverProfile: SecondaryDriverProfile) {
    driverProfile.currentMvr = data;
    driverProfile.currentMvrLoaded = true;
  });

  private readonly setDriverMVRHistoryInfo = (function (data: Array<MVRHistory>, driverProfile: SecondaryDriverProfile) {
    driverProfile.mvrHistory = data;
    driverProfile.mvrHistoryLoaded = true;
  });

  private readonly setDriverNotesInfo = (function (data: Array<DriverNotes>, driverProfile: SecondaryDriverProfile) {
    driverProfile.driverNotes = data;
    driverProfile.driverNotesLoaded = true;
  });

  private readonly setDriverDocumentsInfo = (function (data: Array<DriverDocuments>, driverProfile: SecondaryDriverProfile) {
    driverProfile.driverDocuments = data;
    driverProfile.driverDocumentsLoaded = true;
  });

  private setDriverBaseInfo(secondaryDriverInfoCurrent: SecondaryDriverProfile, secondaryDriverInfoNew: SecondaryDriverProfile) {
    // copy all properties that are retrieved from other services
    const driverNotesInfo = secondaryDriverInfoCurrent ? secondaryDriverInfoCurrent.driverNotes : null;
    const driverDocumentsInfo = secondaryDriverInfoCurrent ? secondaryDriverInfoCurrent.driverDocuments : null;
    const followUpDateInfo = secondaryDriverInfoCurrent ? secondaryDriverInfoCurrent.followUpDate : null;
    const currentMvrInfo = secondaryDriverInfoCurrent ? secondaryDriverInfoCurrent.currentMvr : null;
    const mvrHistoryInfo = secondaryDriverInfoCurrent ? secondaryDriverInfoCurrent.mvrHistory : null;

    // set base data
    secondaryDriverInfoCurrent = secondaryDriverInfoNew;

    // add back copied properties
    this.setDriverNotesInfo(driverNotesInfo, secondaryDriverInfoCurrent);
    this.setDriverDocumentsInfo(driverDocumentsInfo, secondaryDriverInfoCurrent);
    secondaryDriverInfoCurrent.followUpDate = followUpDateInfo;
    this.setDriverCurrentMVRInfo(currentMvrInfo, secondaryDriverInfoCurrent);
    this.setDriverMVRHistoryInfo(mvrHistoryInfo, secondaryDriverInfoCurrent);

  }

  // opens the employee driver form
  private openEmployeeDriverForm(driverProfile: SecondaryDriverProfile, openToSection: string) {
    const rt = (driverProfile.isEmployee ? 'employeedriverform' : 'nonemployeedriverform');
    const queryParams: any = { returnToProfileId: driverProfile.primaryDriverId, returnToTab: 'Non-Employees' };
    if (openToSection) {
      queryParams.openToSection = openToSection;
    }

    this.router.navigate(['/safety/' + rt + '/' + driverProfile.driverId], { queryParams: queryParams });

  }

  addNonEmployeeDriver(primaryDriverProfile: DriverProfile) {
    const queryParams: any = { returnToProfileId: primaryDriverProfile.driverId, returnToTab: 'Non-Employees' };
    this.router.navigate(['safety/newnonemployeedriverform', primaryDriverProfile.groupGuid, primaryDriverProfile.driverId.toString()], { queryParams: queryParams });
  }

  // determines if access to the component is allowed
  private isAccessAllowed(userRightsId: number) {
    let retVal: boolean = false;

    if (this._userRights) {
      const thisRight = this._userRights.filter(r => r.userRightId === userRightsId);
      if ((thisRight.length > 0) && (thisRight[0].permission === 'allow')) {
        retVal = true;
      }
    }

    return retVal;
  }

  private calcCanAddNewDriver() {
    // calc conditions under which the Add New Driver button can be enabled

    // user right to add driver
    this.canAddNewDriver = this.isAccessAllowed(53);

    // based on multiple Non Employees property
    if (this.canAddNewDriver) {
      if (this.allowMultipleNonEmployees) {
        this.canAddNewDriver = true;
      } else {
        this.canAddNewDriver = (this.secondaryDrivers.filter(s => s.isActive).length < 1);
      }
    }
  }

  private handleError(err: HttpErrorResponse) {
    this.errorService.setErrorObject(err.error);
    this.loadingSpinnerService.hide();
  }

  private findSecondaryDriverById(id: any) : SecondaryDriverProfile {
    return this.secondaryDrivers.find(sd => sd.driverId === id);
  }

  ngOnDestroy() {
    this.refreshAllInfoSub.unsubscribe();
    this.refreshBaseInfoSub.unsubscribe();
    this.refreshNotesInfoSub.unsubscribe();
    this.refreshDocumentsInfoSub.unsubscribe();
    this.refreshCurrentMVRInfoSub.unsubscribe();
    this.refreshMVRHistoryInfoSub.unsubscribe();

  }

}

export interface SecondaryDriverProfile extends DriverProfile {
  actionMenuObjectArray: Array<any>;
  collectionDataLoaded: boolean;
}
