<div class="row" *ngIf="driverProfile?.mvrTaskStatus||(!driverProfile?.currentMvrLoaded)||currentMvr">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">Last MVR Reported</h4>
          <app-loading-panel *ngIf="!driverProfile?.currentMvrLoaded"></app-loading-panel>
          <div class="row" *ngIf="currentMvr&&driverProfile?.currentMvrLoaded">
            <div class="col-sm-2 accidentClaimTabLabel">
              License #:
            </div>
            <div *ngIf="canViewDLDOB" class="col-sm-3 accidentClaimTabBold">
              {{(currentMvr?.licenseNumber) ? currentMvr?.licenseNumber + (currentMvr?.licenseState? ' ('+currentMvr?.licenseState+')': ''): 'Unvailable'}}
            </div>
            <div *ngIf="!canViewDLDOB" class="col-sm-3 accidentClaimTabBold">
              Unavailable
            </div>
            <div class="col-sm-2 accidentClaimTabLabel">
              License Status:
            </div>
            <div class="col-sm-3 accidentClaimTabBold">
              {{currentMvr?.licenseStatus ? currentMvr?.licenseStatus : 'Unavailable'}}
            </div>
          </div>
          <div class="row" *ngIf="currentMvr&&driverProfile?.currentMvrLoaded">
            <div class="col-sm-2 accidentClaimTabLabel">
              MVR Status:
            </div>
            <div class="col-sm-3 accidentClaimTabBold">
              {{currentMvr?.mvrStatus ? currentMvr?.mvrStatus : 'Unavailable'}}
            </div>
            <div class="col-sm-2 accidentClaimTabLabel">
              License Class:
            </div>
            <div class="col-sm-3 accidentClaimTabBold">
              {{currentMvr?.licenseClass ? currentMvr?.licenseClass : 'Unavailable'}}
            </div>
          </div>
          <div class="row" *ngIf="currentMvr && driverProfile?.currentMvrLoaded">
            <div class="col-sm-2 accidentClaimTabLabel">
              Requested By:
            </div>
            <div class="col-sm-3 accidentClaimTabBold">
              {{currentMvr?.requestedUserName ? currentMvr?.requestedUserName : 'Unavailable'}}
            </div>
            <div class="col-sm-2 accidentClaimTabLabel">
              Completion Date:
            </div>
            <div *ngIf="currentMvr?.mvrStatus === 'Complete'" class="col-sm-3 accidentClaimTabBold">
              {{currentMvr?.completionDate | date: 'MM/dd/yyyy'}}
            </div>
            <div *ngIf="currentMvr?.mvrStatus !== 'Complete'" class="col-sm-3 accidentClaimTabBold">
              Unavailable
            </div>
          </div>
          <div class="row" *ngIf="currentMvr && driverProfile?.currentMvrLoaded">
            <div class="col-sm-2 accidentClaimTabLabel">
              Requested Date:
            </div>
            <div *ngIf="driverProfile?.mvrRequestDate" class="col-sm-3 accidentClaimTabBold">
              {{driverProfile?.mvrRequestDate | date: 'MM/dd/yyyy'}}
            </div>
            <div *ngIf="!driverProfile?.mvrRequestDate" class="col-sm-3 accidentClaimTabBold">
              Unavailable
            </div>
          </div>
          <div class="row" *ngIf="driverProfile?.mvrTaskStatus">
            <div class="col-sm-12" [ngStyle]="{'color': driverProfile?.mvrTaskStatusColor}">
              {{driverProfile?.mvrTaskStatus}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="fullProfile">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">MVR Violations</h4>
          <app-loading-panel *ngIf="!driverMVRViolationsTableConfig"></app-loading-panel>
          <div *ngIf="driverMVRViolationsTableConfig">
            <app-data-table 
              [columns]="driverMVRViolationsTableConfig?.fields"
              [data]="driverMVRViolationsTableConfig?.data"
              [noRecordsLabel]="driverMVRViolationsTableConfig?.noRecordsLabel"
            ></app-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="canViewMVR">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body mt-3">
          <h4 class="card-title text-muted">MVR Records</h4>
          <app-loading-panel *ngIf="!driverMVRRecordsTableConfig"></app-loading-panel>
          <div *ngIf="driverMVRRecordsTableConfig">
            <app-data-table 
              [columns]="driverMVRRecordsTableConfig.fields"
              [data]="driverMVRRecordsTableConfig.data"
              [noRecordsLabel]="driverMVRViolationsTableConfig?.noRecordsLabel"
              (triggerOpenModal)="openModal($event)"
              (triggerNavigate)="onNavigate($event)"
            ></app-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
