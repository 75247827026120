import { Component, ViewChild, Input, ElementRef, OnInit, Inject, OnChanges, SimpleChange } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardTile } from "../classes-and-interfaces/classes-and-interfaces.component";
import * as Highcharts from 'highcharts';
import { HttpClient } from '@angular/common/http';
import More from 'highcharts/highcharts-more';
import Tree from 'highcharts/modules/treemap';
import Heatmap from 'highcharts/modules/heatmap';
import Tilemap from 'highcharts/modules/tilemap';
import Exporting from 'highcharts/modules/exporting'
import { DashboardService } from '../dashboard-service/dashboard-service.component';
import { ReportRequest } from '../data-grid/data-grid.service';
import { ClientSelectionService } from '../client-selection-service/client-selection-service.component';
import { OnPremDriverService } from '../on-prem-service/on-prem-driver-service.component';
import { OnPremDcService } from '../on-prem-service/on-prem-dc-service.component';
import { DataGridModalService } from '../data-grid-filter-modal/data-grid-filter-modal.service';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { MatDialogRef } from '@angular/material/dialog';
import { DashboardTileZoomComponent } from '../../modals/dashboard-tile-zoom/dashboard-tile-zoom.component';
More(Highcharts);
Tree(Highcharts);
Heatmap(Highcharts);
Tilemap(Highcharts);
Exporting(Highcharts);

@Component({
  selector: 'app-chart-v2',
  templateUrl: './chart-v2.component.html',
  styleUrls: ['./chart-v2.component.css']
})
export class ChartV2Component implements OnInit, OnChanges {

  @ViewChild('container', { static: true }) container: ElementRef;

  @Input() filterStartDate: string;
  @Input() filterEndDate: string;

  @Input() globalDateFilterStartDate: string;
  @Input() globalDateFilterEndDate: string;
  @Input() slicerOptionChosen: string;
  @Input() clientSelectionString: string;
  @Input() card: DashboardTile;

  @Input() TileId = 62;
  chartLoaded = false;
  testConfig: string;
  chartRequest: ChartRequest;
  baseUrl: string;
  Highcharts = Highcharts;
  chart: Highcharts.Chart;
  @Input() ChartOptionsString: string;
  @Input() IsAdminPreview = false;
  @Input() ChartDS: string;
  @Input() LineOfBusinessIdString: string;

  @Input() chartOptions: Highcharts.Options = {

    chart: {
      type: 'pie'
    },
    title: {
      text: ''
    },
    tooltip: {
      pointFormat: '{series.name}: <b> {point.y} </b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        showInLegend: true,
        dataLabels: {
          enabled: false
        }
      },
      series: {
        cursor: 'pointer',
        point: {
          events: {
          }
        }
      }
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
      text: ""
    },
    series: [({
      name: 'Count',
    } as any) as Highcharts.SeriesPieOptions]
  }
  @Input() isOpenedInDialog = false;

  constructor(
    private readonly http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private readonly gridRequest: ReportRequest,
    private readonly loadingSpinnerService: LoadingSpinnerService,
    private readonly dashboardService: DashboardService,
    private readonly clientSelectionService: ClientSelectionService,
    private readonly onPremDriverService: OnPremDriverService,
    private readonly dataGridModalService: DataGridModalService,
    private readonly onPremDcService: OnPremDcService,
    private readonly router: Router,
    private readonly dialogRef: MatDialogRef<DashboardTileZoomComponent>
  ) {

    this.baseUrl = baseUrl;

    this.chartRequest = new ChartRequest();
    this.chartRequest.TileId = this.TileId;

  }

  ngOnInit(): void {
    Highcharts.setOptions({
      lang: {
        decimalPoint: '.',
        thousandsSep: ','
      }
    });
    if (this.card && this.card.jsChartTileConfig) {

      var jsConfString = this.card.jsChartTileConfig;

      try {
        this.chartOptions = JSON.parse(jsConfString) as Highcharts.Options;
        this.chartOptions.title.text = "";
      }
      catch (ex) {
        console.error(this.getCardErrorIdentifier(), ex);
        console.log(jsConfString);
      }
    }
    else if (this.IsAdminPreview && this.ChartOptionsString) {
      try {
        this.chartOptions = JSON.parse(this.ChartOptionsString) as Highcharts.Options;
        this.chartOptions.title.text = "";
      }
      catch (ex) {
        console.error(this.getCardErrorIdentifier(), ex);
        console.log(this.ChartOptionsString);
      }
    }

    if (!this.IsAdminPreview) {
      this.container.nativeElement.id = "container" + this.TileId;
    }
    else { this.container.nativeElement.id = "container"; }

    if (!this.IsAdminPreview) {
      this.chart = Highcharts.chart(this.container.nativeElement.id, this.chartOptions);
      this.chart.showLoading();
      this.chart.reflow();
    }
    this.loadChart();

  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (this.chartLoaded || this.IsAdminPreview) {
      this.loadChart();
    }
  }

  loadChart() {
    if (!this.card && !this.IsAdminPreview) {
      return;
    }
    if (this.IsAdminPreview) {
      this.loadAdminPreview();
      return;
    }
    this.chartRequest.LineOfBusiness = this.card.lineOfBusinessId;

    //initialize date filters
    this.chartRequest.StartDate = new Date();
    this.chartRequest.EndDate = new Date();

    //Set start and end date if filters have values
    if (this.filterStartDate || this.globalDateFilterStartDate) {
      this.chartRequest.StartDate = new Date(this.filterStartDate || this.globalDateFilterStartDate);
    }
    if (this.filterEndDate || this.globalDateFilterEndDate) {
      this.chartRequest.EndDate = new Date(this.filterEndDate || this.globalDateFilterEndDate);
    }

    this.chartRequest.TileId = this.card.tileId;

    this.chartRequest.Client = this.card.clientSelectedArray.join(",");
    this.chartRequest.SlicerValue = this.slicerOptionChosen || this.card.slicerDefaultValue;

    try {
      this.chartOptions.plotOptions.series.point.events.click = (obj) => {
        const data = [] as any;
        data.push({ name: "Category", value: obj.point.category });
        data.push({ name: "SeriesName", value: obj.point.series.name });
        data.push({ name: "PointName", value: obj.point.name });
        for (let option in obj.point.options) {
          if (option != "$type") {
            data.push({ name: option, value: obj.point.options[option] });
          }
        }

        // Comment in for debugging chart click-throughs
        // console.log("--------------")
        // console.log(this.card);
        // console.log(obj.point);
        // console.log(data);
        // console.log("--------------")

        if (this.dashboardService.buildGridRequestHighCharts(this.gridRequest, data, this.card)) {
          this.loadingSpinnerService.show();
          const randomVal = Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 10);
          try {
            sessionStorage.setItem('grid_' + randomVal, JSON.stringify(this.gridRequest));

            if (!this.card.isOnPrem) {
              if (this.isOpenedInDialog) {
                this.dialogRef.close();
              }
              this.router.navigate(['/datagrid/' + randomVal]);
            }
            else {
              this.dataGridModalService.openModal(this.card.dataGridId);
            }
          } catch (e) {
            this.loadingSpinnerService.hide();
          }
        }
      }
    }
    catch (ex) {
      console.error(this.getCardErrorIdentifier(), ex);
    }

    this.chart.reflow();
    setTimeout(() => {
      this.chart.reflow();
    }, 2000);
    this.chart.showLoading();

    if (this.card.isOnPrem) {
      this.getOnPremChartData();
    }
    else {
      this.getChartData();
    }
  }

  loadAdminPreview() {
    if (!this.ChartDS || !this.ChartOptionsString) {
      return;
    }
    try {
      this.chartOptions = JSON.parse(this.ChartOptionsString) as Highcharts.Options;
      this.chartOptions.title.text = "";
    }
    catch (ex) {
      console.error(this.getCardErrorIdentifier(), ex);
      console.log(this.ChartOptionsString);
    }

    this.chartRequest.LineOfBusiness = + this.LineOfBusinessIdString;

    //initialize date filters
    this.chartRequest.StartDate = new Date(this.filterStartDate);
    this.chartRequest.EndDate = new Date(this.filterEndDate);

    if (this.LineOfBusinessIdString) {
      this.chartRequest.Client = this.clientSelectionService.getSavedClientShortNames(+this.LineOfBusinessIdString).join(",");
    }
    else {
      this.chartRequest.Client = "";
    }

    this.chartRequest.PreviewDS = this.ChartDS;

    this.getChartPreviewData();
  }

  public exportChart() {
    const options: Highcharts.ExportingOptions = {
      type: "image/jpeg"
    };

    this.chart.exportChart(options, { title: { text: this.card.title } });
  }

  randomDate(start, end) {
    var date = new Date(+start + Math.random() * (end - start));
    return date;
  }

  private getChartData() {
    this.http.post(this.baseUrl + 'api/Dashboard/GetTileData',
      JSON.stringify(this.chartRequest),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }).subscribe(data => {

        const result: any = data;

        for (let i = 0; i < result.rows.length; i++) {
          try {
            (this.chartOptions.series[i] as any).data = result.rows[i];
          }
          catch (ex) {
            console.error(this.getCardErrorIdentifier(), ex);
          }
        }
        this.finalizeChart();
      });
  }

  private getOnPremChartData() {
    if (!this.card.onPremApiUrl || this.card.onPremApiUrl === '') {
      return;
    }
    if (this.card.isOnPrem && this.card.onPremApiUrl === "DeliveryContractor/charts/5") {
      this.chartRequest.StartDate = this.chartRequest.EndDate;
    }

    const param = {
      "parameters": this.chartRequest
    }
    let url = this.card.onPremApiUrl.replace('{clientCode}', this.clientSelectionString);

    let service: any = null;
    if (this.card.onPremApiUrl.startsWith("DeliveryContractor")) {
      url = url.replace(/^deliverycontractor\//i, "");
      service = this.onPremDcService;
    }
    else {
      service = this.onPremDriverService;
    }

    service.post(url, JSON.stringify(param))
      .subscribe(data => {
        if (data) {
          if (data.datasets) {
            for (let i = 0; i < data.datasets.length; i++) {
              try {
                (this.chartOptions.series[i] as any).data = data.datasets[i].rows;
              }
              catch (ex) {
                console.error(this.getCardErrorIdentifier(), ex);
              }
            }
          }
          else {
            for (let i = 0; i < data.length; i++) {
              try {
                (this.chartOptions.series[i] as any).data = data[i];
              }
              catch (ex) {
                console.error(this.getCardErrorIdentifier(), ex);
              }
            }
          }
        }

        this.finalizeChart();

      });
  }

  finalizeChart() {

    let subtitle = "";

    if (this.card && this.card.tileDateFilterTypeId == 1) {   //range
      subtitle = `${this.chartRequest.StartDate.toLocaleDateString()} thru ${this.chartRequest.EndDate.toLocaleDateString()}`
    }
    else if (this.card && this.card.tileDateFilterTypeId === 2) {  //single date

      if (this.card.isOnPrem && this.card.onPremApiUrl == "DeliveryContractor/charts/5") {
        //exception for Delivery Contractors Time Clock chart
        subtitle = "Date Starting: " + this.chartRequest.StartDate.toLocaleDateString();
      }
      else {
        subtitle = "Date Ending: " + this.chartRequest.EndDate.toLocaleDateString();
      }
    }
    else { //none
      subtitle = "";
    }

    if (this.card && this.slicerOptionChosen) {
      if (this.card && this.card.tileDateFilterTypeId !== 3) {
        subtitle += "<br>"
      }

      subtitle += `${this.card.slicerDisplayName} ${this.card.slicerOperator} ${this.slicerOptionChosen}`;

    }


    if (!this.chartOptions.subtitle) {
      this.chartOptions.subtitle = {
        text: subtitle
      };
    }
    else {
      this.chartOptions.subtitle.text = subtitle;
    }
    this.chartOptions.subtitle.style = { fontSize: "14px", color: "#333333" }

    if (!this.chartOptions.legend) {
      this.chartOptions.legend = {
        itemStyle: {
          fontWeight: "normal", fontSize: "13px"
        }
      };
    }
    else {
      this.chartOptions.legend.itemStyle = { fontWeight: "normal", fontSize: "13px" };
    }


    //clear the title text
    this.chartOptions.title.text = "";
    if (!this.chartOptions.chart) {
      this.chartOptions.chart = {};
    }
    this.chartOptions.chart.style = {
      fontFamily: "Arial, Helvetica, Gadget, sans-serif",
      textOutline: ''
    };

    try {
      this.chart.update((this.chartOptions as any) as Highcharts.Options, true, false, true);
      this.chart.reflow();
    }
    catch (ex) {
      console.error(this.getCardErrorIdentifier(), ex);
    }

    this.chart.hideLoading();
    this.chartLoaded = true;

    setTimeout(() => {
      this.chart.reflow();
    }, 2000);
  }

  private getChartPreviewData() {
    this.http.post(this.baseUrl + 'api/AdminTile/GetTilePreview',
      JSON.stringify(this.chartRequest),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }).subscribe(data => {

        const result: any = data;

        for (let i = 0; i < result.rows.length; i++) {
          try {
            (this.chartOptions.series[i] as any).data = result.rows[i];
          }
          catch (ex) {
            console.error(this.getCardErrorIdentifier(), ex);
          }
        }

        var subtitle = "";

        if (!this.chartOptions.subtitle) {
          this.chartOptions.subtitle = {
            text: subtitle
          };
        }
        else {
          this.chartOptions.subtitle.text = subtitle;
        }
        this.chartOptions.subtitle.style = { fontSize: "14px", color: "#333333" }

        if (!this.chartOptions.legend) {
          this.chartOptions.legend = {
            itemStyle: {
              fontWeight: "normal", fontSize: "13px"
            }
          };
        }
        else {
          this.chartOptions.legend.itemStyle = { fontWeight: "normal", fontSize: "13px" };
        }

        //clear the title text
        this.chartOptions.title.text = "";
        if (!this.chartOptions.chart) {
          this.chartOptions.chart = {};
        }
        this.chartOptions.chart.style = {
          fontFamily: "Arial, Helvetica, Gadget, sans-serif",
          textOutline: ''
        };

        //console.log(JSON.stringify(this.chartOptions));

        if (this.chart) {
          this.chart.destroy();
        }

        this.chart = Highcharts.chart(this.container.nativeElement.id, this.chartOptions);

        this.chartLoaded = true;
      });
  }

  private getCardErrorIdentifier() {
    return `"${this.card.description}" (${this.card.dataGridId})`;
  }
}

export class ChartRequest {
  TileId: number;
  Client: string;
  StartDate: Date;
  EndDate: Date;
  SlicerValue: string;
  LineOfBusiness: number;
  PreviewDS: string;
  PreviewCfg: string;
}
