import { Service } from './service.model';

export class ServiceDriverQualification extends Service {

  isCertificateOfRoadTest?: boolean;
  isCertificateOfViolation?: boolean;
  isClearingHouseLimitedSignoff?: boolean;
  isDriverApplication?: boolean;
  isDriversLicenseUpload?: boolean;
  isDriversLog?: boolean;
  isMvr?: boolean;
  isMedicalCard?: boolean;
  isRecordOfRoadTest?: boolean;

  constructor() {
      super('ServiceDriverQualification');
  }
}
