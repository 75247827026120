import { Service } from './service.model';

export class ServiceDriverLicenseUpload extends Service {

  dueDate?: Date;

  constructor() {
      super('ServiceDriverLicenseUpload');
  }
}
