import { Service } from './service.model';

export class ServiceCertificateOfViolation extends Service {

  processId: number;

  constructor() {
      super('ServiceCertificateOfViolation');
  }
}
